<template>
  <items-data-table-component
      endpoint="problems"
      type="daily problems"
      :routes="routes"/>
</template>
<script>

export default {
  name: "Problems",
  data () {
    return {
      routes: {
        create: 'dashboard.problems.create',
        show: 'dashboard.problems.show',
        edit: 'dashboard.problems.edit'
      }
    }
  }
}
</script>